html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}
div[data-reactroot] {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.nav-container {
  flex: 0 1 auto;
}
.main-content {
  flex: 1 1 auto;
  /* min-height: 100%; */
}
.main-footer {
  flex: 0 1 40px;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.Toastify__toast-container {
  z-index: 99999 !important;
}
.loader {
  z-index: 9999 !important;
  background: #fff;
  /* backdrop-filter: blur(7px); */
  /* background: rgba(255, 255, 255, 0.6); */
}
.loader .spinner-border {
  /* width: 2.5rem;
  height: 2.5rem; */
}

.full-page-loader {
  z-index: 9999 !important;
  background-color: #fff;
  /* backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.6); */
}

.overlay-loader {
  z-index: 9999 !important;
  background-color: #fff;
  backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.6);
}

.full-page-loader .spinner-border {
  width: 3rem;
  height: 3rem;
}

.pac-container {
  z-index: 999999 !important;
}

.page-not-found {
  min-width: 100vw;
  min-height: 100vh;
}

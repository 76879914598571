.date-time-modal {
  .modal-dialog {
    max-width: max-content !important;
  }
  .modal-content {
    width: 850px;
    padding: 10px 20px;
  }
  .modal-body {
    padding: 0px;
    .date-box {
      width: 80px !important;
      height: 80px !important;
      border-radius: 8px !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fbfbff;
      border: 1px solid #bbbbbb;
      color: $label-color-3;
      &:hover {
        background: var(--bg-secondary-color) !important;
        border-color: var(--bg-secondary-color) !important;
        color: #fff !important;
      }
      &.active-date {
        background: var(--bg-secondary-color) !important;
        border-color: var(--bg-secondary-color) !important;
        color: #fff !important;
      }
      p {
        white-space: pre;
      }
    }
    .time-box {
      width: max-content;
      margin-bottom: 15px;
      margin-right: 10px;
      padding: 3px 20px;
      border-radius: 5px !important;
      cursor: pointer;
      background: #fbfbff;
      border: 1px solid #bbbbbb;
      text-align: center;
      color: $label-color-3;
      &:hover {
        background: var(--bg-secondary-color) !important;
        border-color: var(--bg-secondary-color) !important;
        color: #fff !important;
      }
      &.active-time {
        background: var(--bg-secondary-color) !important;
        border-color: var(--bg-secondary-color) !important;
        color: #fff !important;
      }
    }
    .confirm-btn {
      background: var(--bg-primary-color);
      transition: 0.3s all ease-in-out;
      border: 1px solid transparent !important;
      color: white;
      border-radius: 3px;
      &:hover {
        color: var(--label-primary-color) !important;
        background: white !important;
        border-color: var(--label-primary-color) !important;
      }
    }
  }
}
@media (max-width: 1200px) {
  .date-time-modal {
    .modal-content {
      padding: 5px 15px !important;
    }
    .modal-header {
      .modal-title {
        font-size: 25px !important;
      }
    }
    .modal-body {
      h3 {
        font-size: 23px !important;
      }
      .date-box {
        width: 60px !important;
        height: 60px !important;
        p {
          font-size: 18px !important;
        }
        span {
          font-size: 16px;
        }
      }
      .time-box {
        span {
          font-size: 18px !important;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .date-time-modal {
    .modal-header {
      .modal-title {
        font-size: 22px !important;
      }
    }
    .modal-body {
      h3 {
        font-size: 20px !important;
      }
      .date-box {
        p {
          font-size: 16px !important;
        }
        span {
          font-size: 16px;
        }
      }
      .time-box {
        padding: 3px 10px;
        span {
          font-size: 14px !important;
        }
      }
    }
  }
}

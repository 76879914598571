.food-service-modal {
  .modal-dialog {
    max-width: 750px !important;
  }
  .modal-content {
    max-width: 750px;
    border-radius: 0px !important;
  }
  .modal-header {
    padding: 20px 20px !important;
    padding-bottom: 0px !important;
    border: 0px;
    font-size: 34px !important;
    color: #0f2343 !important;
    button {
      font-size: 20px;
    }
  }
  .modal-body {
    padding-top: 0px !important;
    padding: 20px 20px !important;
    min-height: max-content;
    overflow: hidden;
    height: 450px;
    overflow-y: auto;
  }
  .add-to-cart {
    background: var(--bg-primary-color);
    border: 1px solid transparent !important;
    color: white;
    transition: 0.3s;
    padding: 10px 98px;
    &:hover {
      color: var(--label-primary-color);
      background: white;
      border: 1px solid var(--bg-primary-color) !important;
    }
  }
  .food-item-dicard {
    background: #dc3545;
    border: 1px solid transparent !important;
    color: white;
    transition: 0.3s;
    padding: 10px 98px;
    &:hover {
      color: #dc3545;
      background: white;
      border: 1px solid #dc3545 !important;
    }
  }
  button {
    &:focus {
      outline: none !important;
    }
  }
}
@media (max-width: 767px) {
  .food-service-modal {
    .modal-header {
      h1 {
        font-size: 25px !important;
      }
      .modal-title {
        h1 {
          font-size: 20px !important;
        }
      }
    }
    .modal-body {
      padding: 20px 20px !important;
      height: 300px;
      h3 {
        font-size: 20px !important;
        font-weight: 600 !important;
      }
      label {
        font-size: 17px !important;
      }
      p {
        font-size: 13px !important;
      }
      span {
        font-size: 16px !important;
      }
    }
    .add-to-cart {
      background: #1f5ea9;
      border: 1px solid transparent !important;
      color: white;
      transition: 0.3s;
      font-size: 15px !important;
      &:hover {
        color: #1f5ea9;
        background: white;
        border: 1px solid #1f5ea9 !important;
      }
    }
    .food-item-dicard {
      background: #dc3545;
      border: 1px solid transparent !important;
      color: white;
      transition: 0.3s;
      font-size: 15px !important;
      &:hover {
        color: #dc3545;
        background: white;
        border: 1px solid #dc3545 !important;
      }
    }
    button {
      &:focus {
        outline: none !important;
      }
    }
  }
}

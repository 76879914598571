.taxi-banner {
  background-size: 100% 500px !important;
  background-position: top !important;
  padding-top: 450px;
  padding-bottom: 50px;
  // position: relative;
}

.taxi-tabs {
  @extend .font-proxima;
  width: 100%;
  background-color: #fff;
  // position: relative;
  z-index: 10;
  // position: absolute;
  top: -18rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  // transform: translateY(-18rem);
  box-shadow: 0px 4px 14px rgba(173, 173, 183, 0.2);
  .nav-tabs {
    border-bottom: 0;
    .nav-link {
      color: #333333;
      padding: 7px 10px;
      font-size: 23px;
      margin-right: 20px;
      font-weight: 700;
      border-width: 0 0 5px !important;
      border-color: $bg-color-1 !important;
      &.active {
        border: none;
        color: var(--label-secondary-color);
        border-bottom: 3px solid var(--label-secondary-color) !important;
      }
    }
  }

  .input-bar {
    padding: 6px 15px;
    font-size: 21px;
    color: #7d7d7d;
  }

  .cab-type {
    .nav-tabs {
      border: 0;
      .nav-link {
        background-color: transparent;
        border: 0;
        color: #676767;
        padding: 7px 2px;
        margin-right: 2rem;
        font-size: 21px;
        font-weight: 500;
        &.active {
          border-bottom: 4px solid var(--label-primary-color) !important;
          background-color: transparent;
          color: var(--label-primary-color) !important;
        }
      }
    }
  }
  .cab-model {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-transform: uppercase;
    width: 120px;
    height: 120px;
    background-color: $bg-color-1;
    color: #2b383e;
    &.active {
      background-color: #2b383e;
      color: #fff;
    }
  }
  .select-package {
    width: 247px;
    padding: 15px 9px 15px 50px !important;
    font-size: 19px;
    z-index: 5;
  }
  .package-box {
    z-index: 5;
    &::before {
      width: 24px;
      height: 21px;
      content: '';
      position: absolute;
      display: block;
      z-index: 10;
      left: 10px;
      top: 20px;
      background: url('../../public/images/car-icon.svg') no-repeat;
    }
  }

  .input-image {
    top: 20px;
    left: 15px;
  }

  .active-ride-btn {
    background: var(--bg-primary-color) !important;
    border: var(--bg-primary-color);
    color: white;
  }
}
.local-cab-section {
  .cab-type {
    .nav-tabs {
      border: 0;
      .nav-link {
        background-color: transparent;
        border: 0;
        color: #676767;
        padding: 7px 2px;
        margin-right: 2rem;
        font-size: 21px;
        font-weight: 500;
        &.active {
          border-bottom: 5px solid var(--label-primary-color) !important;
          background-color: transparent;
          color: var(--label-primary-color) !important;
        }
      }
    }
  }
  .cab-model {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-transform: uppercase;
    width: 120px;
    height: 120px;
    background-color: $bg-color-1;
    color: #2b383e;
    &.active {
      background-color: #2b383e;
      color: #fff;
    }
  }
}

.search-btn {
  background-color: var(--bg-primary-color);
  color: black;
  border-radius: 2px;
  border: 1px solid var(--label-primary-color);
  padding: 10px 60px;
  font-size: 23px;
  font-weight: 600;
  transition: 0.3s;
  &:hover {
    color: var(--label-primary-color);
    background: white;
    border-color: var(--label-primary-color);
  }
  .spinner-border {
    width: 1.5rem !important;
    height: 1.5rem !important;
    border: 0.2rem solid currentColor !important;
    border-right-color: transparent !important;
  }
}

@media (max-width: 1200px) {
  .taxi-banner {
    padding-top: 120px;
    padding-bottom: 30px;
  }
  .taxi-heading {
    font-size: 25px !important;
  }
  .taxi-sub-heading {
    font-size: 22px !important;
  }
  .taxi-tabs {
    // transform: translateY(-13.5rem);
    padding-bottom: 18px !important;
    h2 {
      font-size: 25px !important;
    }
    .input-bar {
      padding: 15px 12px;
      font-size: 18px;
    }
    .form-label {
      font-size: 23px !important;
    }
    .ride-tabbing {
      .nav-link {
        padding: 5px 2px;
        margin-right: 1.5rem;
        font-size: 20px !important;
        &.active {
          // border-bottom: 3px solid var(--label-primary-color) !important;
        }
      }
      .tab-content {
        .tab-pane {
          h6 {
            font-size: 20px !important;
          }
        }
      }
    }
    .local-cab-section {
      .cab-type {
        .nav-tabs {
          .nav-link {
            padding: 7px 2px;
            margin-right: 2rem;
            font-size: 21px;
            border-width: 0 0 3px !important;
            &.active {
              border-bottom: 3px solid var(--bg-secondary-color) !important;
            }
          }
        }
      }
      .cab-model {
        display: block;
        width: 100% !important;
        height: unset !important;
        padding: 5px 10px !important;
        margin-bottom: 5px !important;
        img {
          width: 60px !important;
        }
        span {
          font-size: 18px !important;
        }
      }
    }
    .ride-btn {
      font-size: 20px !important;
    }
    .select-package {
      width: 230px;
      padding: 12px 5px 5px 40px !important;
      font-size: 22px;
    }
    .package-box {
      &::before {
        top: 15px;
      }
    }
    .way-trip-round {
      span {
        font-size: 20px !important;
      }
      p {
        font-size: 18px !important;
      }
      .form-check-inline {
        font-size: 18px !important;
      }
    }
  }
  .search-btn {
    padding: 8px 50px;
    font-size: 20px !important;
  }
}
@media (max-width: 767px) {
  .taxi-heading {
    font-size: 22px !important;
  }
  .taxi-sub-heading {
    font-size: 18px !important;
  }
  .taxi-tabs {
    // transform: translateY(-14.5rem);
    padding-bottom: 15px !important;
    h2 {
      font-size: 20px !important;
    }
    .input-bar {
      padding: 5px 5px;
      font-size: 16px;
    }
    .form-label {
      font-size: 18px !important;
    }
    .ride-tabbing {
      .nav-link {
        padding: 5px 2px;
        margin-right: 1rem !important;
        font-size: 18px !important;
        &.active {
          // border-bottom: 3px solid var(--label-primary-color) !important;
        }
      }
    }
    .local-cab-section {
      .cab-type {
        .nav-tabs {
          .nav-link {
            padding: 7px 2px;
            margin-right: 2rem;
            font-size: 21px;
            &.active {
              border-bottom: 5px solid var(--label-primary-color) !important;
            }
          }
        }
      }
      .cab-model {
        padding: 5px 10px !important;
        img {
          width: 50px !important;
        }
        span {
          font-size: 15px !important;
        }
      }
    }
    .ride-btn {
      font-size: 18px !important;
    }
    .select-package {
      width: 200px;
      padding: 10px 5px 5px 40px !important;
      font-size: 19px;
    }
    .package-box {
      &::before {
        top: 15px;
      }
    }
    .way-trip-round {
      span {
        font-size: 20px !important;
      }
      p {
        font-size: 18px !important;
        margin: 0px !important;
      }
      .form-check-inline {
        font-size: 16px !important;
      }
    }
  }
  .search-btn {
    padding: 8px 35px;
    font-size: 18px !important;
  }
}

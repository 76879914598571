//main-slider
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

@media (min-width: 992px){
.col-lg-2 {
    flex: 0 0 auto;
    width: 22.666667%;
} }
.home-slider {
  h3 {
    font-family: 'Inter', sans-serif;
  }

  .carousel-indicators {
    display: none !important;
  }
  .carousel-control-prev {
    width: unset !important;
    padding: 5px;
    height: max-content !important;
    opacity: unset !important;
    top: 50% !important;
    left: 8% !important;
    background: white !important;
    .carousel-control-prev-icon {
      background-image: url('/images/prevArrow.svg') !important;
    }
  }
  .carousel-control-next {
    width: unset !important;
    padding: 5px;
    height: max-content !important;
    opacity: unset !important;
    top: 50% !important;
    right: 8% !important;
    background: white !important;
    .carousel-control-next-icon {
      background-image: url('/images/nextArrow.svg') !important;
    }
  }
  .order-now-btn {
    border: 1px solid transparent !important;
    transition: 0.3s;
    &:hover {
      color: white !important;
      background: transparent !important;
      border: 1px solid white !important;
    }
  }
}
//
.our-services-section {
  .services {
    min-height: 171px;
    border: 1px solid rgba(112, 123, 142, 0.5);
    box-shadow: 0px 3px 6px rgba(112, 123, 142, 0.2);
    border-radius: 10px;
  }
  .view-more-btn {
    border: 1px solid transparent;
    background: #11afe2;
    color: white !important;
    border-radius: 6px;
    transition: 0.3s;
    &:hover {
      border: 1px solid #11afe2;
      background: white;
      color: #11afe2 !important;
    }
  }
}

// Why Choose Us
.why-choose-us {
  background: var(--bg-primary-color);
}

// How It Works
.how-works-section {
  .icon-box {
    width: 121px;
    height: 140px;
    border: 10px solid #f9b60b;
  }
}

// User Counter
.user-counter {
  background: linear-gradient(rgba(73, 109, 139, 0.7), rgba(73, 109, 139, 0.7)),
    url('/images/user-count-banner.png') no-repeat;
}
//

// Responsive
@media (max-width: 1200px) {
  .home-slider {
    p {
      font-size: 30px !important;
    }
    h3 {
      font-size: 50px !important;
    }
    button {
      font-size: 20px !important;
      padding: 5px 10px !important;
    }
    img {
      height: 350px !important;
    }
    .carousel-control-next {
      padding: 1px !important;
      right: 5% !important;
      width: 25px !important;
      height: 25px !important;
      .carousel-control-next-icon {
        background-size: 12px !important;
      }
    }
    .carousel-control-prev {
      padding: 1px !important;
      left: 5% !important;
      width: 25px !important;
      height: 25px !important;
      .carousel-control-prev-icon {
        background-size: 12px !important;
      }
    }
  }
  //our-services-section
  .our-services-section {
    h5 {
      font-size: 35px !important;
    }
    .services {
      min-height: 150px !important;
      img {
        height: 50px;
      }
      p {
        font-size: 23px !important;
      }
    }
    .view-more-btn {
      font-size: 20px !important;
      padding: 8px 15px !important;
    }
  }
  // why choose us
  .why-choose-us {
    h2 {
      font-size: 40px !important;
    }
    p {
      font-size: 23px !important;
    }
    ol {
      font-size: 20px !important;
    }
  }
  // how works section
  .how-works-section {
    h5 {
      font-size: 40px !important;
    }
    p {
      font-size: 23px !important;
    }
    .icon-box {
      width: 120px;
      height: 120px;
      border: 10px solid #f9b60b;
      img {
        width: 100px;
      }
    }
  }
  // user-counter
  .user-counter {
    h3 {
      font-size: 23px !important;
    }
    p {
      font-size: 20px !important;
    }
    img {
      width: 50px;
    }
  }
}
@media (max-width: 767px) {
  .home-slider {
    p {
      font-size: 20px !important;
    }
    h3 {
      font-size: 23px !important;
    }
    button {
      font-size: 12px !important;
      padding: 5px 10px !important;
    }

    img {
      height: 200px !important;
    }
    .carousel-control-next {
      padding: 1px !important;
      width: 20px !important;
      height: 20px !important;
      right: 3% !important;
      .carousel-control-next-icon {
        background-size: 8px !important;
      }
    }
    .carousel-control-prev {
      padding: 1px !important;
      width: 20px !important;
      height: 20px !important;
      left: 3% !important;
      .carousel-control-prev-icon {
        background-size: 8px !important;
      }
    }
  }
  //our-services-section
  .our-services-section {
    h5 {
      font-size: 30px !important;
    }
    .services {
      min-height: 100px !important;
      img {
        height: 50px;
      }
      p {
        font-size: 20px !important;
        margin: 0px !important;
      }
    }
    .view-more-btn {
      font-size: 16px !important;
      padding: 8px 15px !important;
    }
  }
  // why choose us
  .why-choose-us {
    h2 {
      font-size: 30px !important;
    }
    p {
      font-size: 18px !important;
    }
    ol {
      font-size: 16px !important;
    }
  }
  // how works section
  .how-works-section {
    h5 {
      font-size: 30px !important;
    }
    p {
      font-size: 16px !important;
    }
    // .icon-box {
    //   width: 100px;
    //   height: 100px;
    //   border: 10px solid #f9b60b;
    //   img {
    //     width: 50px;
    //   }
    // }
  }
  // user-counter
  .user-counter {
    h3 {
      font-size: 20px !important;
    }
    p {
      font-size: 16px !important;
    }
    img {
      width: 50px;
    }
  }
}
.before-login{
  display: flex;
    gap: 71px;
    // align-content: flex-start;
    justify-content: center;
}
.before-logins{
  display: flex;
    gap: 71px;
    // align-content: flex-start;
    // justify-content: center;
}

.Toastify__toast--success {
  background: #ffffff !important;
  font-size: 14px !important;
  color: $label-color-10 !important;
  font-weight: 400 !important;
  border-left: #00b865 solid 5px !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 10px 40px !important;
}
.Toastify__toast--info {
  background: #ffffff !important;
  font-size: 14px !important;
  color: $label-color-10 !important;
  font-weight: 400 !important;
  border-left: #3498db solid 5px !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 10px 40px !important;
}
.Toastify__close-button {
  color: $label-color-10 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-top: 15px;
}
.Toastify__toast--error {
  background: #fff !important;
  border-left: #e74c3c solid 5px !important;
  font-size: 14px !important;
  color: $label-color-10 !important;
  font-weight: 400 !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 10px 40px !important;
}
.Toastify__toast--warning {
  background: #fff !important;
  border-left: #f1c40f solid 5px !important;
  font-size: 14px !important;
  color: $label-color-10 !important;
  font-weight: 400 !important;
  box-shadow: rgb(0 0 0 / 25%) 0px 10px 40px !important;
}

.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.fs-24 {
  font-size: 24px;
}
.fs-25 {
  font-size: 25px;
}
.fs-26 {
  font-size: 26px;
}
.fs-27 {
  font-size: 27px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-32 {
  font-size: 32px;
}
.fs-34 {
  font-size: 34px;
}
.fs-35 {
  font-size: 35px;
}
.fs-40 {
  font-size: 40px;
}
.fs-45 {
  font-size: 45px;
}
.fs-50 {
  font-size: 50px;
}
.fs-60 {
  font-size: 60px;
}
.fs-55 {
  font-size: 55px;
}
.fs-70 {
  font-size: 70px !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}

// font colors
.label-color-1 {
  color: $label-color-1 !important;
}
.label-color-2 {
  color: $label-color-2 !important;
}
.label-color-3 {
  color: $label-color-3 !important;
}
.label-color-4 {
  color: $label-color-4 !important;
}
.label-color-8 {
  color: var(--label-secondary-color) !important;
}
.label-color-10 {
  color: $label-color-10 !important;
}
.label-color-23 {
  color: $label-color-23 !important;
}
.label-color-24 {
  color: $label-color-24 !important;
}
.label-color-25 {
  color: $label-color-25 !important;
}
.label-color-success {
  color: $label-color-success !important;
}
.label-color-danger {
  color: $label-color-danger !important;
}

.label-primary-color {
  color: var(--label-primary-color);
}
.label-secondary-color {
  color: var(--label-secondary-color);
}
// bg colors

.bg-primary-color {
  background: var(--bg-primary-color);
}
.bg-secondary-color {
  background: var(--bg-secondary-color);
}
.bg-color-1 {
  background: $bg-color-1;
}
// .bg-color-2 {
//   background: $bg-color-2;
// }
.bg-color-3 {
  background: $bg-color-3;
}
.bg-color-4 {
  background: $bg-color-4;
}
.bg-color-5 {
  background: $bg-color-5;
}

// border-color
.border-color-1 {
  border-color: #495057;
}
.border-color-2 {
  border-color: #ececec;
}

// padding
.px-6 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

// font-family
.font-proxima {
  font-family: 'proxima_nova';
  font-style: normal;
}

.font-sfpro {
  font-family: 'SF_Pro_Display';
  font-style: normal;
}
.font-quando {
  font-family: 'Quando';
  font-weight: normal;
}

// width
.w-90 {
  width: 90% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-60 {
  width: 70% !important;
}
.w-70 {
  width: 70% !important;
}
.w-85 {
  width: 85% !important;
}
.w-80 {
  width: 80% !important;
}
.rounded-2 {
  border-radius: 0.5rem !important;
}

.w-max-content {
  width: max-content !important;
}
.h-max-content {
  height: max-content !important;
}

.resize-none {
  resize: none !important;
}
.mt-70 {
  margin-top: 70px !important;
}

.appearance-none::-webkit-outer-spin-button,
.appearance-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// order active class
.order-active-shadow {
  box-shadow: 0 0.5rem 1rem rgba(48, 154, 211, 30%) !important;
  border-color: var(--bg-primary-color) !important;
}
// Slider
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 10px;
  background: var(--bg-primary-color);
}

// .container {
//   max-width: 1676px !important;
// }

.btn-disabled {
  color: $label-color-4 !important;
  background: $bg-color-1 !important;
  border-color: $bg-color-1 !important;
  cursor: not-allowed !important;
  &:hover {
    color: $label-color-4 !important;
    background: f5f5f5 !important;
    border-color: $bg-color-1 !important;
  }
}

@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';

@media (max-width: 1200px) {
  .mt-70 {
    margin-top: 55px !important;
  }
}
@media (max-width: 768px) {
  .px-6 {
    padding: unset !important;
  }
}

.checkout-module {
  .checkout-heading {
    background: var(--bg-primary-color);
  }
  input {
    border-radius: 5px;
    &:focus {
      outline: none !important;
    }
    &:focus-visible {
      box-shadow: none !important;
    }
  }
  textarea {
    border: 1px solid #a4a4a4;
    border-radius: 5px;
    &:focus {
      outline: none !important;
    }
    &:focus-visible {
      box-shadow: none !important;
    }
  }
  .leave-tip-box {
    label {
      width: 55px;
      height: 55px;
      border-radius: 4px 0px 0px 4px;
      background: $label-color-23;
    }
    input {
      width: 240px;
      height: 55px;
      border-radius: 0px 4px 4px 0px;
    }
  }
  .form-check-input {
    &:checked {
      background-color: $label-color-23 !important;
      border-color: $label-color-23 !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .continue-shopping-btn {
    background: #535353;
    color: white;
    border-radius: 5px;
    transition: 0.3s;
    border: 1px solid transparent;
    &:hover {
      background: white;
      color: #535353;
      border: 1px solid #535353;
    }
  }
  .place-order-btn {
    background: var(--label-primary-color);
    color: white;
    border-radius: 5px;
    transition: 0.3s;
    border: 1px solid transparent;
    &:hover {
      background: white;
      color: var(--label-primary-color);
      border: 1px solid var(--label-primary-color);
    }
  }
  .input-box {
    width: 100%;
    color: $label-color-10 !important;
    font-size: 1.25rem;
  }
}
@media (max-width: 768px) {
  .checkout-module {
    .checkout-heading {
      h2 {
        font-size: 20px !important;
      }
    }
  }
}

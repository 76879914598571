.over-history-slider {
  .slick-arrow {
    border-radius: 50%;
    background: white !important;
  }
  .slick-slider {
    .slick-next {
      width: 45px !important;
      height: 45px !important;
      background: url('../../public/images/nextArrow.svg') no-repeat center center, white !important;
      background-size: 15px !important;
      right: -50px;
      &::before {
        content: unset !important;
      }
    }
    .slick-prev {
      width: 45px !important;
      height: 45px !important;
      transform: rotate(180deg);
      background: url('../../public/images/nextArrow.svg') no-repeat center center, white !important;
      background-size: 15px !important;
      z-index: 1;
      top: 41%;
      left: -50px;
      &::before {
        content: unset !important;
      }
    }
  }
  .over-slider-inner {
    width: 200px;
    cursor: pointer;
    transition: 0.3s;
    .slider-img-box {
      height: 150px !important;
    }
    p {
      color: $label-color-10;
      min-height: 55px !important;
      line-height: 1;
    }
    &:hover {
      background: #e2edf2 !important;
      p {
        color: #016fb0 !important;
      }
    }
    &.active-slider-inner {
      background: #e2edf2 !important;
      p {
        color: #016fb0 !important;
      }
    }
  }
}
.my-order-tabbing {
  .nav-tabs {
    border: 0px !important;
    margin-bottom: 3px !important;
    .nav-item {
      .nav-link {
        width: 230px !important;
        height: 67px !important;
        border-radius: 0px !important;
        border: 0px !important;
        margin-right: 2px !important;
        font-size: 20px !important;
        font-family: 'SF_Pro_Display';
        font-weight: 500 !important;
        background: #fff;
        color: black;
        &.active {
          background: #f3f3f3;
          color: var(--label-primary-color);
        }
      }
    }
  }
  .tab-content {
    background: #f3f3f3;
  }
}
.booking-summery {
  width: 230px !important;
  height: 67px !important;
  background: var(--bg-primary-color);
  color: white;
  margin: 0 0 3px;
}
.booking-summery-box {
  background: #f3f3f3;
  .location-box {
    position: relative;
    ::before {
      height: 56%;
      border-left: 1px solid $label-color-3;
      content: ' ';
      position: absolute;
      top: 26px;
      left: 7px;
    }
  }
}
.cancel-btn {
  // width: 185px;
  height: 55px;
  background: #f74949;
  border-radius: 4px;
  color: white;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid #f74949;
    background: white;
    color: #f74949;
  }
}

@media (max-width: 1200px) {
  .over-history-slider {
    .slick-slider {
      .slick-next {
        width: 35px !important;
        height: 35px !important;
        background-size: 10px !important;
        right: 10px;
      }
      .slick-prev {
        width: 35px !important;
        height: 35px !important;
        background-size: 10px !important;
        left: 10px;
      }
    }

    .over-slider-inner {
      width: 130px !important;
      font-size: 18px !important;
      .slider-img-box {
        height: 100px !important;
        width: 130px !important;
      }
      p {
        min-height: 45px !important;
      }
    }
  }
  .my-order-tabbing {
    .nav-tabs {
      .nav-item {
        .nav-link {
          width: unset !important;
          height: unset !important;
          font-size: 18px !important;
        }
      }
    }
  }
  .booking-summery {
    width: unset !important;
    height: unset !important;
    font-size: 18px !important;
    padding: 8px 16px !important;
  }
}
@media (max-width: 767px) {
  .my-order-container {
    flex-direction: column-reverse !important;
  }
  .booking-summery-box {
    .order-id-box {
      p {
        font-size: 18px !important;
      }
    }
    .location-box {
      width: 100% !important;
      h2 {
        font-size: 20px !important;
      }
      p {
        font-size: 18px !important;
      }
    }
    .driver-details-box {
      h2 {
        font-size: 20px !important;
      }
      p {
        font-size: 18px !important;
      }
    }
  }
  .cancel-btn {
    width: unset !important;
    height: unset !important;
    font-size: 18px !important;
  }
}

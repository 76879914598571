.food-service-cart {
  .delete-icon {
    width: 50px;
    height: 50px;
  }
  .count-btns {
    button {
      font-size: 20px;
      width: 45px;
      height: 40px;
    }
    input {
      font-size: 20px;
      width: 45px;
      height: 40px;
    }
  }
  .update-cart-btn {
    width: 222px;
    height: 60px;
    color: white;
    background: #83bb25;
    border: 1px solid transparent;
    transition: 0.3s;
    &:hover {
      color: #83bb25;
      background: white;
      border: 1px solid #83bb25;
    }
  }
  .coupon-code-box {
    input {
      width: 100%;
      height: 40px;
      color: #868686;
      border-radius: 4px 0px 0px 4px;
      &:focus {
        outline: none !important;
      }
      &:focus-visible {
        box-shadow: unset !important;
      }
    }
    button {
      white-space: nowrap !important;
      height: 40px;
      border-radius: 0px 4px 4px 0px;
      background: #d4ecf9;
      transition: 0.3s;
      &:focus {
        outline: none !important;
      }
      &:focus-visible {
        box-shadow: unset !important;
      }
      &:hover {
        border-color: #d4ecf9 !important;
        background: white;
      }
    }
  }
  .process-btn {
    width: 389px;
    height: 66px;
    color: white;
    background: var(--label-primary-color);
    border: 1px solid transparent;
    font-weight: 600 !important;
    transition: 0.3s;
    &:hover {
      color: var(--label-primary-color);
      background: white;
      border: 1px solid var(--label-primary-color);
    }
  }
}

@media (max-width: 767px) {
  .checkout-heading {
    h1 {
      font-size: 35px !important;
    }
  }
}

//
.courier-tabs {
  @extend .font-proxima;
  background-color: #fff;
  position: relative;
  z-index: 10;
  box-shadow: 0px 4px 14px rgba(173, 173, 183, 0.2);
  .nav-tabs {
    border-bottom: 0;
    .nav-link {
      color: #333333;
      padding: 7px 10px;
      font-size: 28px;
      margin-right: 40px;
      font-weight: 700;
      border-width: 0 0 5px !important;
      border-color: $bg-color-1 !important;
      &.active {
        border: none;
        color: var(--label-secondary-color);
        border-bottom: 5px solid var(--label-secondary-color) !important;
      }
    }
  }
  .select-package {
    width: 247px;
    padding: 15px 9px 15px 50px !important;
    font-size: 19px;
    z-index: 5;
  }
  .package-box {
    z-index: 5;
    &::before {
      width: 24px;
      height: 21px;
      content: '';
      position: absolute;
      display: block;
      z-index: 10;
      left: 10px;
      top: 20px;
      background: url('../../public/images/car-icon.svg') no-repeat;
    }
  }

  .input-image {
    top: 20px;
    left: 15px;
  }

  .active-ride-btn {
    background: var(--bg-primary-color) !important;
    border: var(--bg-primary-color);
    color: white;
  }
}
.courier-choose-carrier {
  .courier-type {
    .nav-tabs {
      border: 0;
      .nav-link {
        background-color: transparent;
        border: 0;
        color: #676767;
        padding: 7px 2px;
        margin-right: 2rem;
        font-size: 21px;
        font-weight: 500;
        &.active {
          border-bottom: 5px solid var(--label-primary-color) !important;
          background-color: transparent;
          color: var(--label-primary-color) !important;
        }
      }
    }
  }
  .courier-model {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-transform: uppercase;
    width: 120px;
    height: 120px;
    background-color: $bg-color-1;
    color: #2b383e;
    &.active {
      background-color: #2b383e;
      color: #fff;
    }
  }
}
.input-bar {
  padding: 6px 15px;
  font-size: 21px;
  color: #7d7d7d;
}

@media (max-width: 1200px) {
  .input-bar {
    padding: 15px 12px;
    font-size: 18px;
  }
  .courier-heading {
    font-size: 25px !important;
  }
  .courier-sub-heading {
    font-size: 22px !important;
  }
  //
  .courier-tabs {
    .nav-tabs {
      .nav-link {
        padding: 7px 8px !important;
        font-size: 25px !important;
        margin-right: 10px !important;
        font-weight: 600 !important;
      }
    }
  }
  //
  .courier-choose-carrier {
    .courier-type {
      .nav-tabs {
        .nav-link {
          padding: 7px 2px;
          margin-right: 2rem;
          font-size: 21px;
          border-width: 0 0 3px !important;
          &.active {
            border-bottom: 3px solid var(--bg-secondary-color) !important;
          }
        }
      }
    }
    .courier-model {
      display: block;
      width: 100% !important;
      height: unset !important;
      padding: 5px 10px !important;
      margin-bottom: 5px !important;
      img {
        width: 60px !important;
      }
      span {
        font-size: 18px !important;
      }
    }
  }
  .receiver-details-box {
    .receiver-details-input {
      width: unset !important;
    }
  }
}
@media (max-width: 767px) {
  .input-bar {
    padding: 5px 5px;
    font-size: 16px;
  }
  .courier-heading {
    font-size: 22px !important;
  }
  .courier-sub-heading {
    font-size: 18px !important;
  }
  //
  .courier-tabs {
    .nav-tabs {
      .nav-link {
        padding: 5px 10px !important;
        font-size: 17px !important;
        margin-right: 5px !important;
        font-weight: 600 !important;
      }
    }
  }
  //
  .courier-choose-carrier {
    .courier-type {
      .nav-tabs {
        .nav-link {
          padding: 7px 2px;
          margin-right: 2rem;
          font-size: 21px;
          &.active {
            border-bottom: 5px solid var(--label-primary-color) !important;
          }
        }
      }
    }
    .courier-model {
      padding: 5px 10px !important;
      img {
        width: 50px !important;
      }
      span {
        font-size: 15px !important;
      }
    }
  }
  .courier-header {
    .px-6 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .courier-paging {
      font-size: 16px !important;
    }
    .courier-box {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 20px !important;
      }
    }
  }
}

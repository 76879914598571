.login-modal {
  .modal-header {
    .modal-title {
      // border-color: #274455;
      border-bottom: 1px solid #274455;
    }

    .btn-close {
      background-color: transparent;
      width: 25px;
      height: 25px;
      opacity: 1;
      position: absolute;
      top: 1.8rem;
      border-radius: 100%;
      right: 1.5rem;
      @extend .p-0, .m-0;
    }
  }
  .form-icon {
    position: absolute;
    top: 17px;
    right: 10px;
    z-index: 10;
  }
  .form-bar {
    padding: 8px 15px;
    padding-right: 40px;
    font-size: 18px;
    border: 1px solid #ededed;
    border-radius: 0;
  }
  .login-btn {
    background: linear-gradient(
      180deg,
      #038cc8 0%,
      var(--label-primary-color) 119.17%,
      var(--label-primary-color) 119.17%
    );
    transition: 0.3s all ease-in-out;
    border: 1px solid transparent !important;
    color: white;
    border-radius: 3px;
    &:hover {
      color: var(--label-primary-color) !important;
      background: white !important;
      border-color: var(--label-primary-color) !important;
    }
  }
}
.login-loader {
  width: 20px;
  height: 20px;
}
@media (max-width: 767px) {
  .order-confirm-modal {
    .modal-title {
      text-align: left !important;
      font-size: 23px;
    }
  }
}

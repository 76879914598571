.plumber-banner {
  // background: linear-gradient(rgba(73, 109, 139, 0.7), rgba(73, 109, 139, 0.7)),
  //   url("/images/plumber-banner.png") no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 500px !important;
  background-position: top !important;
  height: 480px;
  margin-top: 70px;
}
.plumber-service {
  @extend .font-proxima;
  background-color: #fff;
  position: relative;
  z-index: 10;
  transform: translateY(-5rem);
  box-shadow: 0px 4px 14px rgba(173, 173, 183, 0.2);
  .plumber-list {
    .plumber-box {
      border-radius: 8px;
      .add-btn {
        background: var(--bg-primary-color) !important;
        border: 1px solid var(--label-primary-color) !important;
        border-radius: 2px;
        transition: 0.2s all ease-in-out;
        padding: 1px 34.5px !important;
        &:hover {
          color: var(--label-primary-color) !important;
          background-color: #ffffff !important;
        }
      }
    }
    .plumber-list-box {
      min-height: max-content;
      max-height: 600px;
      overflow: auto;
      overflow-y: scroll;
    }
  }
  .confirm-booking-btn {
    background-color: var(--bg-primary-color);
    color: #fff;
    border-radius: 2px;
    border: 1px solid var(--label-primary-color);
    padding: 10px 60px;
    font-size: 26px;
    font-weight: 600;
    transition: 0.3s;
    &:hover {
      color: var(--label-primary-color) !important;
      background: white !important;
      border-color: var(--label-primary-color) !important;
    }
  }
}
.plumber-cart-box {
  border-radius: 15px;
  border: 1px solid #d3d3d3;
  .plumber-cart-body {
    min-height: 352px;
    max-height: 400px;
    overflow: auto;
    overflow-y: scroll;
  }

  .price-tag {
    color: #fe9800 !important;
  }
  .plumber-cart-bottom {
    .disable-proceed-btn {
      border-radius: 0px 0px 15px 15px;
      background: #535353;
      border: 1px solid transparent !important;
      transition: 0.3sq;
      cursor: not-allowed;
    }
    .proceed-btn {
      border-radius: 0px 0px 15px 15px;
      background: var(--bg-primary-color) !important;
      border: 1px solid var(--label-primary-color) !important;
      transition: 0.2s all ease-in-out;
      &:hover {
        color: var(--label-primary-color) !important;
        background-color: #ffffff !important;
      }
    }
  }
}
.plumber-counter-button {
  button {
    background: var(--bg-primary-color);
    transition: 0.3s all ease-in-out;
    border: 1px solid transparent !important;
    color: white;
    border-radius: 3px;
    &:hover {
      color: var(--label-primary-color) !important;
      background: white !important;
      border-color: var(--label-primary-color) !important;
    }
  }
}
.plumber-time-slot {
  .date-box {
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
    color: #969696;
    cursor: pointer;
    &:hover {
      border-color: var(--label-primary-color);
      background: var(--bg-primary-color);
      color: white;
    }
    &.active-date {
      border-color: var(--label-primary-color);
      background: var(--bg-primary-color);
      color: white;
    }
  }
  .time-box {
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    white-space: nowrap;
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
    color: #969696;
    cursor: pointer;
    &:hover {
      border-color: var(--label-primary-color);
      background: var(--bg-primary-color);
      color: white;
    }
    &.active-date {
      border-color: var(--label-primary-color);
      background: var(--bg-primary-color);
      color: white;
    }
  }
  .add-address-box {
    width: 350px;
    cursor: pointer;
    height: 150px;
    border-radius: 12px !important;
    border: 0.5px solid #0f2343;
  }
}

.automatic-assigning-box {
  border-radius: 13px !important;
  background-color: $bg-color-1 !important;
  .select-btn {
    background: var(--bg-primary-color);
    border: 1px solid transparent;
    font-size: 20px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    &:hover {
      border-color: var(--label-primary-color);
      background: white;
      color: var(--label-primary-color);
    }
  }
}
.plumber-suggestion-box {
  border-radius: 5px;
  border: 1px solid #eff0f1;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.03);
  .select-btn {
    border: 1px solid var(--label-primary-color);
    font-size: 20px;
    font-weight: 600;
    color: var(--label-primary-color);
    cursor: pointer;
    background: white;
    &:hover {
      background: var(--bg-primary-color);
      color: white;
    }
  }
}
@media (max-width: 1200px) {
  .plumber-data-modal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          p {
            font-size: 16px !important;
          }
          span {
            font-size: 16px !important;
          }
        }
      }
    }
  }
  .plumber-banner {
    height: 380px;
  }
  .plumber-service {
    h1 {
      font-size: 25px !important;
    }
    .confirm-booking-btn {
      padding: 8px 50px;
      font-size: 20px !important;
    }
    .plumber-list {
      .plumber-box {
        span {
          font-size: 18px !important;
        }
        .add-btn {
          padding: 3px 25px !important;
        }
      }
    }
  }
  .plumber-counter-button {
    button {
      font-size: 18px;
    }
  }
  .plumber-suggestion-box {
    h3 {
      font-size: 20px !important;
    }
    p {
      font-size: 14px !important;
      svg {
        font-size: 13px !important;
      }
    }
    img {
      width: 70px;
      height: 70px;
    }
    .select-btn {
      font-size: 16px;
    }
    button {
      font-size: 14px !important;
    }
  }
}
@media (max-width: 767px) {
  .automatic-assigning-box {
    h2 {
      font-size: 18px !important;
    }
    p {
      font-size: 14px !important;
    }
    img {
      margin-left: 10px;
      width: 40px !important;
      height: 40px !important;
    }
    button {
      font-size: 16px !important;
    }
  }
  .plumber-banner {
    height: 280px;
  }
  .plumber-service {
    h1 {
      font-size: 22px !important;
    }
    .confirm-booking-btn {
      padding: 8px 35px;
      font-size: 18px !important;
    }
    .plumber-list {
      .plumber-box {
        box-shadow: unset !important;
        span {
          font-size: 14px !important;
        }
        .add-btn {
          font-size: 14px !important;
          padding: 3px 24px !important;
        }
      }
      .plumber-list-box {
        max-height: 300px;
      }
    }
  }
  .plumber-counter-button {
    button {
      height: 29px;
      font-size: 18px;
    }
    .count-number {
      height: 29px !important;
    }
  }
}

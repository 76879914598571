.courier-banner {
  // background: url("../../public/images/courier-banner.png") no-repeat center;
  background-size: 100% 500px !important;
  background-position: top !important;
  // height: 500px;
  padding-top:450px;
  padding-bottom: 50px;
}

.courier-service {
  @extend .font-proxima;
  background-color: #fff;
  z-index: 10;
  // transform: translateY(-18rem);
  box-shadow: 0px 4px 14px rgba(173, 173, 183, 0.2);
  .form-check-input {
    width: 25px !important;
    height: 25px !important;
    margin: 0;
  }

  .product-container {
    .product-item:nth-child(2n + 1) {
      min-width: 450px;
      border-right: 1px solid #a2a2a2;
    }
  }
  .product-container {
    .product-item:nth-child(2n + 2) {
      padding-left: 5rem;
    }
  }

  .add-btn {
    width: 50px;
    height: 35px;
    border: none;
  }

  input[type='file'] {
    display: none;
  }
  .imagePicker {
    width: 150px;
    height: 100px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .mover {
    min-width: 100px;
  }
  .delivery-tabbing {
    .nav-link {
      padding: 5px 2px;
      margin-right: 1.5rem;
      font-size: 20px !important;
      &.active {
        border-bottom: 3px solid var(--label-primary-color) !important;
      }
    }
    .tab-content {
      .tab-pane {
        h6 {
          font-size: 20px !important;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .courier-banner {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .courier-heading {
    font-size: 25px !important;
  }
  .courier-sub-heading {
    font-size: 22px !important;
  }
  .courier-pages {
    font-size: 20px !important;
  }
  .product-container {
    .product-item:nth-child(2n + 1) {
      min-width: unset !important;
      border-right: unset !important;
    }
  }
  .product-container {
    .product-item:nth-child(2n + 2) {
      padding-left: 5rem;
    }
  }
  .courier-service {
    .form-check-input {
      width: 23px !important;
      height: 23px !important;
    }

    .product-container {
      .product-item {
        .form-control {
          height: 28px !important;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .courier-banner {
  }
  .courier-heading {
    font-size: 22px !important;
  }
  .courier-sub-heading {
    font-size: 18px !important;
  }
  .courier-pages {
    font-size: 18px !important;
  }
  .product-container {
    .product-item:nth-child(2n + 2) {
      padding-left: unset !important;
    }
  }
  .courier-service {
    .form-check-input {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
.new-buttons{
  color: black; 
    // border-radius: 2px;
    border: 3px solid black;
    padding: 10px 60px;
    font-size: 23px;
    font-weight: 600;
    transition: 0;
    border-radius: 40px !important;
}

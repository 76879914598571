// Fonts colors
$label-color-1: #333333;
$label-color-2: #000000;
$label-color-3: #969696;
$label-color-4: #889fb4;
// $label-color-8: #008ecc; // dynamic var(--label-secondary-color)
$label-color-10: #666666;
// $label-color-11: #0064a0; // dynamic var(--label-primary-color)
$label-color-23: #4bcff9;
$label-color-24: #eba919;
$label-color-25: #743e3e;
$label-color-success: #25a90f;
$label-color-danger: #f41919;

// Bg colors
$bg-color-1: #f5f5f5;
// $bg-color-2: #0064a0; //dymanic
$bg-color-3: #effaf4;
$bg-color-4: #eaedf2;
$bg-color-5: #213242;
// $bg-color-6: #213242; // dynamic
:root {
  --bg-primary-color: #002e5b;
  --bg-secondary-color: #309ad3;
  --label-primary-color: #002e5b;
  --label-secondary-color: #309ad3;
}

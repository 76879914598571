/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on January 19, 2022 */

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/fontsfree-net-proxima-nova-sbold-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/fontsfree-net-proxima-nova-sbold-webfont.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_black-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/proxima_nova_black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_bold-webfont.woff2") format("woff2"),
    url("../../public/fonts/proxima_nova_bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_condensed_light-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/proxima_nova_condensed_light-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_condensed_thin-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/proxima_nova_condensed_thin-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_extra_condensed_light-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/proxima_nova_extra_condensed_light-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_extra_condensed_thin-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/proxima_nova_extra_condensed_thin-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_extrabold-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/proxima_nova_extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proxima_nova_thin-webfont.woff2") format("woff2"),
    url("../../public/fonts/proxima_nova_thin-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../../public/fonts/proximanova-regular-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF_Pro_Display";
  src: url("../../public/fonts/sfprodisplaybold-webfont.woff2") format("woff2"),
    url("../../public/fonts/sfprodisplaybold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF_Pro_Display";
  src: url("../../public/fonts/sfprodisplaymedium-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/sfprodisplaymedium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF_Pro_Display";
  src: url("../../public/fonts/sfprodisplayregular-webfont.woff2")
      format("woff2"),
    url("../../public/fonts/sfprodisplayregular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

.footer {
  background-color: var(--bg-primary-color);
  @extend .font-sfpro;

  .footer-border {
    border-top: 2px solid #ffffff;
  }
  .footer-icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
}
.cms {
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 767px) {
  .cms {
    display: block;
    justify-content: space-evenly;
}
  .footer {
    padding-bottom: 50px;
    background-color: var(--bg-primary-color);
    @extend .font-sfpro;
    .logo-section {
      p {
        font-size: 14px !important;
      }
    }
    .featured-service {
      h2 {
        color: var(--bg-secondary-color);
        font-size: 25px;
      }
      ul {
        font-size: 17px !important;
      }
    }
    .footer-icon {
      border-radius: 100%;
      font-size: 14px;
    }
  }
}

.apporio-navbar {
  @extend .font-proxima;
  position: fixed;
  padding-left: 6rem !important;
  padding-right: 6rem !important;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 30;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  .nav-link {
    transition: 0.2s all ease-in-out;
    color: #33344b !important;
    .active-link {
      color: #f9b60b !important;
    }
  }
  .nav-item {
    padding-left: 25px !important;
  }
  .login-sign-up {
    background: var(--bg-primary-color) !important;
    border: 1px solid var(--label-primary-color) !important;
    border-radius: 2px;
    transition: 0.2s all ease-in-out;
    &:hover {
      color: var(--label-primary-color) !important;
      background-color: #ffff !important;
    }
    &.btn-login {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .user-menu {
    .dropdown {
      &:hover {
        .dropdown-menu {
          display: block !important;
        }
      }
    }
    .dropdown-menu {
      background: rgba(255, 255, 255, 0.97) !important;
      top: 52px;
      z-index: 20;
      margin: 0;
      padding: 0;
      font-size: 24px;
      border: 0;
      top: 100%;
      right: 0px;
      border-radius: 0;
      box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 20%) !important;
    }
    .dropdown-item {
      font-weight: 600;
      background: transparent !important;
      &:hover {
        color: var(--label-secondary-color);
        background: #e9ecef !important;
      }
    }
  }
  .mobile-menu-bar {
    display: none !important;
  }
}
.header-menu-bar {
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 20;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  a {
    &:hover {
      color: var(--label-secondary-color) !important;
    }
  }
}
.header-location {
  background: rgba(255, 255, 255, 0.95) !important;
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 20;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);

  .location-header-input {
    width: 100%;
    color: $label-color-10 !important;
    outline: 0;
    padding: 1rem 0.5rem;
    font-size: 1.25rem;
    border: 0;
    border-bottom: 2px solid black;
    background: transparent;
    border-radius: 0;
  }
}
.food-search-bar {
  border-radius: 25px;
  border: 1px solid var(--bg-secondary-color);
  box-shadow: 0 0.5rem 1rem rgba(0, 142, 204, 15%) !important;
  .location-icon {
    width: 50px;
    border-radius: 25px 0px 0px 25px;
  }
  input {
    width: 300px;
    &:focus {
      outline: unset !important;
    }
  }
  button {
    border-radius: 25px;
    border: 1px solid transparent;
    transition: 0.3s;
    &:hover {
      border-color: var(--bg-secondary-color);
      background: white;
      color: var(--bg-secondary-color) !important;
    }
  }
}
.mobile-menu-navbar {
  display: none !important;
  .menu-icon-box {
    width: 40px;
  }
}

@media (max-width: 1200px) {
  .header-location {
    top: 50px;
    padding: 0px !important ;
  }
  .apporio-navbar {
    padding-left: unset !important;
    padding-right: unset !important;
    .navbar-inner {
      display: none;
    }
    .active-link {
      color: #f9b60b !important;
    }
    .mobile-menu-bar {
      margin-top: 52px;
      display: block !important;
      width: 0% !important;
      height: 100vh;
      top: 0px;
      background: white;
      position: absolute;
      transition: width 0.3s;
      overflow: hidden;
    }
    .mobile-user-menu {
      .dropdown {
        .dropdown-toggle {
          &:focus {
            box-shadow: unset !important;
          }
          &::after {
            content: unset !important;
          }
        }
        .dropdown-menu {
          left: unset !important;
          right: 0px !important;
        }
      }
    }
    .mobile-menu-bar-collapse {
      width: 100% !important;
    }
  }
  .mobile-responsive-bar {
    display: block !important;
  }
  .mobile-menu-navbar {
    display: flex !important;
  }
}
@media (max-width: 768px) {
  .apporio-navbar {
    padding-left: unset !important;
    padding-right: unset !important;
  }
  .banner-address-section {
    h1 {
      font-size: 30px !important;
    }
    p {
      font-size: 22px !important;
    }
  }
  .food-search-bar {
    border-radius: 25px;
    .location-icon {
      width: 30px;
      border-radius: 25px 0px 0px 25px;
    }
    input {
      width: 180px;
    }
    button {
      border-radius: 25px;
      font-size: 16px !important;
    }
  }
  .search-bar {
    input {
      border: 1px solid #dfe1e5 !important;
      box-shadow: 0 1px 6px 0 rgb(32 33 36 / 28%) !important;
      &:focus {
        outline: unset !important;
      }
    }
  }
}

.order-items-box {
  min-height: max-content;
  max-height: 750px;
  overflow: auto;
  overflow-y: scroll;
}

@media (max-width: 1024px) {
  .order-items-box {
    min-height: max-content;
    max-height: 650px;
    overflow: auto;
    overflow-y: scroll;
  }
}
@media (max-width: 1200px) {
  .order-items-box {
    .grocery-food-box {
      img {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }
}
@media (max-width: 767px) {
  .order-items-box {
    .location-box {
      width: 100% !important;
      h2 {
        font-size: 20px !important;
      }
      p {
        font-size: 18px !important;
      }
    }
    .payment-type {
      font-size: 16px !important;
    }
    .price-type {
      font-size: 18px !important;
    }
    .order-id-box {
      h3 {
        font-size: 18px !important ;
      }
      p {
        font-size: 18px !important;
      }
      img {
        width: 35px !important;
        height: 35px !important;
      }
    }
    .grocery-food-box {
      img {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
}

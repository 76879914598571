.categorie-box {
  .categorie-heading {
    border-radius: 5px 5px 0px 0px;
  }
  .categorie-item {
    span {
      &:hover {
        color: var(--label-secondary-color);
      }
    }
    &.categorie-item-active {
      span {
        color: var(--label-secondary-color) !important;
      }
    }
  }
}

// .veg-btn {
//   transition: 0.3s;
//   font-weight: 600;
//   color: $label-color-10;
//   &:hover {
//     color: white !important;
//     background: #338cd9 !important;
//   }
// }
.page-count-items {
  .pagination-container {
    .pagination-item {
      border: 0px !important;
      color: black !important;
      background-color: #ededed !important;
      font-size: 21px;
      font-family: 'proxima_nova';
      font-style: normal;
      justify-content: center;
      margin: 0px !important;
      width: 44px;
      height: 44px;
      border-radius: 0px !important;
      &:focus {
        outline: none;
        box-shadow: unset !important;
      }
      &:hover {
        background-color: var(--bg-primary-color) !important;
        color: white !important;
        .arrow {
          background-color: var(--bg-primary-color) !important;
          color: white !important;
          &::before {
            border-color: white !important;
          }
        }
      }
      &:first-child {
        border-radius: 5px 0px 0px 5px !important;
      }
      &:last-child {
        border-radius: 0px 5px 5px 0px !important;
      }
      &.selected {
        background-color: var(--bg-primary-color) !important;
        color: white !important;
      }
    }
  }
}
.food-product-list {
  .card {
    min-height: 415px;
    .card-img-top {
      height: 220px !important;
      padding: 3px 30px;
    }
    .card-body {
      h3 {
        font-size: 23px !important;
      }
      p {
        font-size: 23px !important;
        color: var(--label-secondary-color);
      }
      .food-item-heading {
        color: #000;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 55px;
        h3 {
          font-size: 21px !important;
        }
      }
      .add-btn {
        border: 1px solid transparent;
        background: var(--bg-primary-color);
        color: white;
        border-radius: 5px;
        // padding: 8px 67.5px;
        transition: 0.3s;
        font-size: 23px;
        &:hover {
          border-color: var(--label-primary-color);
          background: white;
          color: var(--label-primary-color);
        }
      }
      .add-more-btns {
        width: 55px;
        height: 53px;
        box-sizing: border-box;
        padding: unset !important;
      }
      .add-items-count {
        width: 55px;
        height: 53px;
        color: var(--label-secondary-color);
        font-size: 30px;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid var(--label-secondary-color);
      }
    }
  }
  .add-more-btns {
    width: 55px;
    height: 53px;
    box-sizing: border-box;
    padding: unset !important;
  }
  .add-items-count {
    width: 55px;
    height: 53px;
    color: var(--label-secondary-color);
    font-size: 30px;
    border-radius: 5px;
    border: 1px solid var(--label-secondary-color);
    box-sizing: border-box;
  }
}
.price-range-slider {
  padding: 30px 20px;
  .rc-slider-step {
    border-radius: 0px;
    box-shadow: unset !important;
    background: #d3d3d3 !important;
    padding: 6px 0px !important;
    z-index: 0;
  }
  .rc-slider-track {
    border-radius: 0px;
    box-shadow: unset !important;
    background-color: var(--bg-primary-color) !important;
    padding: 6px 0px !important;
    z-index: 1;
  }
  .rc-slider-handle {
    width: 21px;
    height: 21px;
    border-radius: unset !important;
    background-color: var(--bg-primary-color) !important;
    border: unset !important;
    z-index: 1;
    &:focus-visible {
      outline: unset !important;
    }
    &:active {
      box-shadow: unset !important;
    }
  }
  .multi-range-slider {
    box-shadow: unset !important;
    border: 0px !important;
  }
  .bar {
    .bar-left {
      border-radius: 0px;
      box-shadow: unset !important;
      background: #d3d3d3 !important;
      padding: 6px 0px !important;
    }
    .bar-inner {
      box-shadow: unset !important;
      background: #d3d3d3 !important;
      border: 0px !important;
    }
  }
  &::before {
    background-color: var(--bg-secondary-color) !important;
    box-shadow: unset !important;
    border-radius: unset !important;
    border: 0px !important;
  }
}
.label {
  display: none !important;
}
.ruler {
  display: none !important;
}

.label {
  display: none !important;
}
.ruler {
  display: none !important;
}

.filter-btn {
  border: 1px solid transparent !important;
  transition: 0.3s !important;
  &:hover {
    background: white !important;
    border-color: var(--label-secondary-color) !important;
    color: var(--label-secondary-color) !important;
  }
}
.filter-btn {
  border: 1px solid transparent !important;
  transition: 0.3s !important;
  &:hover {
    background: white !important;
    border-color: var(--label-secondary-color) !important;
    color: var(--label-secondary-color) !important;
  }
}
.restaurant-items {
  .add-btn {
    border: 1px solid transparent;
    background: var(--bg-primary-color);
    color: white;
    border-radius: 5px;
    padding: 8px 30px;
    transition: 0.3s;
    font-size: 18px;
    &:hover {
      border-color: var(--label-primary-color);
      background: white;
      color: var(--label-primary-color);
    }
  }
}
.grocery-cart-btn {
  border: 1px solid transparent;
  background: var(--bg-primary-color);
  color: white;
  border-radius: 5px;
  padding: 8px 30px;
  transition: 0.3s;
  font-size: 18px;
  &:hover {
    border-color: var(--label-primary-color);
    background: white;
    color: var(--label-primary-color);
  }
}
@media (max-width: 767px) {
  .restaurant-items {
    .food-product-list {
      .card {
        min-height: unset !important;
        .card-img-top {
          height: 150px !important;
          padding: unset !important;
        }
      }
      .card-body {
        padding: 10px 5px !important;
        .veg-nonveg-icon {
          width: 15px;
        }
        .food-item-heading {
          min-height: 48px !important;
          h3 {
            font-size: 18px !important;
          }
        }
        .food-item-price {
          font-size: 14px !important;
          font-weight: bold !important;
          white-space: nowrap !important;
        }
        .add-btn {
          font-size: 14px !important;
          // padding: 5px 40px !important;
        }
        .add-items-count {
          height: 39px !important;
          font-size: 15px !important;
        }
        .add-more-btns {
          height: 39px !important;
        }
      }
    }
    .page-count-items {
      .pagination-container {
        padding: 0px !important;
        .pagination-item {
          font-size: 18px !important;
          width: 34px;
          height: 34px;
        }
      }
    }
    .food-item-type-heading {
      font-size: 30px !important;
      margin: 0px !important;
    }
  }
  .grocery-cart-btn {
    padding: 5px 15px !important;
    font-size: 14px !important;
    svg {
      font-size: 14px !important;
    }
  }
  .cart-btn {
    font-size: 16px !important;
    padding: 5px 5px !important;
    svg {
      font-size: 18px !important;
    }
  }
}

.page {
  width: 650px;
  margin: 10% auto 0;
  padding: 20px;
  background: #e5e5e5;

  &-title {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  &-content {
    background: #fff;
    padding: 25px 0;
  }
}

.placeholder-content {
  height: 205px;
  overflow: hidden;
  background: #000;
  position: relative;

  // Animation
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: #f6f7f8; // Fallback
  background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
  background-size: 1300px; // Animation Area

  &_item {
    width: 100%;
    height: 20px;
    position: absolute;
    background: #fff;
    z-index: 2;

    &:after,
    &:before {
      width: inherit;
      height: inherit;
      content: '';
      position: absolute;
    }

    &:nth-child(1) {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 20px;
      left: 0;
      width: 10%;
      height: 90px;
    }

    &:nth-child(3) {
      top: 0px;
      left: 0;
      width: 10%;
      height: 100%;
    }

    &:nth-child(4) {
      top: 20px;
      width: 20px;
      left: 170px;
      height: 90px;
    }

    &:nth-child(5) {
      top: 40px;
      left: 190px;
      height: 12px;
    }

    &:nth-child(6) {
      top: 75px;
      left: 190px;
      height: 12px;
    }

    &:nth-child(7) {
      top: 20px;
      right: 0;
      width: 23%;
      height: 20px;
    }

    &:nth-child(8) {
      top: 0;
      right: 0;
      width: 10%;
      height: 100%;
    }

    &:nth-child(9) {
      top: 110px;
      height: 17px;
      left: 0;
    }

    &:nth-child(10) {
      top: 149px;
      height: 12px;
      left: 0;
    }

    &:nth-child(11) {
      top: 183px;
      left: 0;
      height: 100%;
    }
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}

.product-skeleton-loader {
  .card {
    width: 280px;
    padding: 14px;
    font-family: '@microsoft yahei light', sans-serif;
    border-radius: 18px;
    overflow: hidden;
    border: 0px !important;
  }

  .cover {
    height: 270px;
    border-radius: 8px;
    overflow: hidden;
  }
  /* add to img on load event */
  .animate {
    animation: animate 1s ease;
  }
  @keyframes animate {
    from {
      transform: rotateX(20deg);
      opacity: 0;
    }
    to {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }

  .content {
    position: relative;
    color: #333;
    margin-top: 8px;
  }
  .content small {
    display: block;
    margin: 8px 0;
    color: grey;
  }

  /* sleleton elements */
  .skeleton {
    background: linear-gradient(90deg, hsl(210, 15%, 88%), hsl(210, 15%, 95%), hsl(210, 15%, 88%));
    background-size: 200%;
    animation: loader 1s infinite reverse;
  }
  h2.skeleton {
    width: 50%;
    height: 1em;
  }
  small.skeleton {
    height: 1rem;
    width: 4rem;
  }
  p.skeleton {
    height: 3em;
  }

  /* skeleton animation */
  @keyframes loader {
    from {
      background-position: -100% 0;
    }
    to {
      background-position: 100% 0;
    }
  }
}

@media (max-width: 767px) {
  .product-skeleton-loader {
    .card {
      width: 165px;
    }
  }

  .all-category {
    .product-skeleton-loader {
      .card {
        width: 100%;
      }
    }
  }
}

.heading {
  border-bottom: 5px solid black;
}
.food-delivery-banner {
  // background: url("../../public/images/food-service/food-banner.png");
  min-height: 480px;
  max-height: max-content;
  background-size: cover;
}
.food-service {
  .food-brand-box {
    width: 400px;
    .food-brand {
      min-height: 300px !important;
      transition: 0.3s;
      img {
        width: 100% !important;
        height: 300px !important;
        border-radius: 3px !important;
        padding: 35px 30px;
      }
      &:hover {
        .food-brand-overlay {
          display: flex !important;
        }
      }
    }
  }
  .food-brand-overlay {
    height: 230px !important;
    background-color: rgba(133, 29, 6, 0.7) !important;
    margin-top: 35px;
    width: 249px !important;
    margin-left: 29px;
    text-align: center;
  }
  .slick-slider {
    .slick-next {
      width: 45px !important;
      height: 45px !important;
      background: url('../../public/images/nextArrow.svg') no-repeat center center !important;
      right: -70px;
      &::before {
        content: unset !important;
      }
    }
    .slick-prev {
      width: 45px !important;
      height: 45px !important;
      transform: rotate(180deg);
      background: url('../../public/images/nextArrow.svg') no-repeat center center !important;
      z-index: 1;
      top: 41%;
      left: -70px;
      &::before {
        content: unset !important;
      }
    }
  }
}
.mobile-app-section {
  background: url('../../public/images/mobile-app-background.png') no-repeat center bottom;
}
.play-img {
  height: 50px !important;
}
@media (max-width: 1200px) {
  .food-service {
    .food-brand-box {
      .food-brand {
        img {
          padding: 15px 5px !important;
        }
      }
    }
  }
  // sub footer section
  .mobile-app-section {
    .play-img {
      height: 50px !important;
    }
    h1 {
      font-size: 30px !important;
    }
    p {
      font-size: 16px !important;
    }
    img {
      width: 150px !important;
      height: 150px !important;
    }
  }
  .food-delivery-banner {
    min-height: 250px !important ;
  }
  .food-service {
    .food-brand-box {
      width: 100px;
      .food-brand {
        min-height: 180px !important;
        img {
          height: 180px !important;
        }
      }
    }
    .slick-slider {
      .slick-next {
        width: 30px !important;
        height: 30px !important;
        right: 20px;
        border-radius: 25px;
        background: url('../../public/images/nextArrow.svg') no-repeat center center, white !important;
        background-size: 20px 20px, cover !important;
      }
      .slick-prev {
        width: 30px !important;
        height: 30px !important;
        top: 41%;
        left: 20px;
        border-radius: 25px;
        background: url('../../public/images/nextArrow.svg') no-repeat center center, white !important;
        background-size: 20px 20px, cover !important;
      }
    }
  }
}

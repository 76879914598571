.food-service-all-restaruant {
  .card {
    border-radius: 0px !important;
    .card-img-top {
      height: 200px;
      padding: 3px 30px;
    }
    .card-body {
      .heding-box {
        width: 85% !important;
        h2 {
          font-size: 23px !important;
          font-weight: bold !important;
        }
      }
      .rating-box {
        width: 15% !important;
        span {
          padding: 3px 3px !important;
          font-size: 17px !important;
        }
      }
      p {
        color: rgba(7, 22, 35, 0.9);
        font-weight: 600;
      }
      h2 {
        font-weight: 600;
        font-size: 28px !important;
        min-height: 52px;
        max-height: 82px !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .food-label {
        color: #fe9800;
      }
      .description-box {
        display: block;
        max-width: 100%;
        height: 38px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 1;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.mobile-menu-bottom-bar {
  z-index: 5;
}
@media (max-width: 767px) {
  .food-service-all-restaruant {
    .card {
      border-radius: 0px !important;
      .card-img-top {
        height: 100px !important;
        padding: unset !important;
      }
      .card-body {
        .heding-box {
          width: 85% !important;
          h2 {
            font-size: 20px !important;
          }
        }
        .rating-box {
          width: 15% !important;
          span {
            padding: 3px 3px !important;
            font-size: 10px !important;
          }
        }
        p {
          color: rgba(7, 22, 35, 0.9);
          font-weight: 600;
        }
        h2 {
          font-weight: 600;
        }
        .food-label {
          font-size: 14px !important;
          color: #fe9800;
        }
        .distance-box {
          font-size: 15px !important;
          svg {
            font-size: 12px !important;
          }
        }
        .description-box {
          font-size: 13px !important;
          height: 15px !important;
          white-space: nowrap !important;
        }
      }
    }
  }
  .restaurant-header {
    .px-6 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .restaurant-paging {
      font-size: 16px !important;
    }
    .restaurant-box {
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 20px !important;
      }
    }
    .veg-nonveg {
      font-size: 20px;
      img {
        width: 20px;
      }
    }
  }
}
